import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'utils/styles/colors';
import Header from 'common/header/components/Header';
import Homepage from 'common/homepage/components/Homepage';
import News from 'common/news/components/News';
import NewsArticle from 'common/news/components/article/Article';
import Footer from 'common/footer/components/Footer';

const App = () => (
  <Content>
    <Helmet>
      <title>GOAT Group</title>
      <meta
        name="description"
        content="GOAT Group represents the leading platforms for authentic sneakers, apparel and accessories. Operating five distinct brands– GOAT, Flight Club, Grailed, Sneakers.com and alias –GOAT Group has a global community of more than 60M members across 170 countries."
      />
    </Helmet>

    <Router>
      <Header />
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route path="/news/:articleSlug" component={NewsArticle} />
        <Route path="/news" component={News} />
        <Redirect to="/" />
      </Switch>
      <Footer />
    </Router>
  </Content>
);

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: 'Diatype', sans-serif;
  background-color: ${colors.white};
`;

export default App;
