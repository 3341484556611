/* eslint-disable no-restricted-syntax */
import React from 'react';
import styled from 'styled-components';
import colors from 'utils/styles/colors';
import media from 'utils/styles/media';
import letterSpacing from 'utils/styles/letterSpacing';
import {
  BRANDS,
  GOAT,
  GRAILED,
  FLIGHT_CLUB,
  ALIAS,
  SNEAKERS_DOT_COM,
} from 'common/constants/brands';

const AboutGoatGroup = () => (
  <AboutGoatGroup.Wrapper data-testid="about-goatgroup">
    <AboutGoatGroup.Copy>
      GOAT Group represents the leading platforms for authentic sneakers, apparel and accessories.
      Operating five distinct brands–
      <AboutGoatGroup.Link
        href={BRANDS[GOAT].websiteUrl}
        target="_blank"
        rel="noopener noreferrer"
        data-testid="goat-link"
      >
        GOAT
      </AboutGoatGroup.Link>
      ,{' '}
      <AboutGoatGroup.Link
        href={BRANDS[FLIGHT_CLUB].websiteUrl}
        target="_blank"
        rel="noopener noreferrer"
        data-testid="flight-club-link"
      >
        Flight Club
      </AboutGoatGroup.Link>
      ,{' '}
      <AboutGoatGroup.Link
        href={BRANDS[GRAILED].websiteUrl}
        target="_blank"
        rel="noopener noreferrer"
        data-testid="grailed-link"
      >
        Grailed
      </AboutGoatGroup.Link>
      ,{' '}
      <AboutGoatGroup.Link
        href={BRANDS[SNEAKERS_DOT_COM].websiteUrl}
        target="_blank"
        rel="noopener noreferrer"
        data-testid="sneakers-dot-com-link"
      >
        Sneakers.com
      </AboutGoatGroup.Link>{' '}
      and{' '}
      <AboutGoatGroup.Link
        href={BRANDS[ALIAS].websiteUrl}
        target="_blank"
        rel="noopener noreferrer"
        data-testid="alias-link"
      >
        alias
      </AboutGoatGroup.Link>
      –GOAT Group has a global community of more than 60M members across 170 countries.
    </AboutGoatGroup.Copy>
  </AboutGoatGroup.Wrapper>
);

AboutGoatGroup.Wrapper = styled.div`
  margin: 40px 0 40px;
  color: ${colors.gray};

  ${media['640']`
    margin-bottom: 45px;
  `}
`;
AboutGoatGroup.Copy = styled.div`
  ${letterSpacing};
  font-size: 24px;
  line-height: 28px;

  ${media['640']`
    font-size: 32px;
    line-height: 38px;
  `}
`;
AboutGoatGroup.Link = styled.a`
  white-space: nowrap;
  color: inherit;
`;

export default AboutGoatGroup;
